






import { Vue, Component } from 'vue-property-decorator'
import ResolveCompanyId from '@/mixins/ResolveCompanyId'
import Subjects from './components/Subjects.vue'
import { userMappers } from '@/store/modules/user'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters(['userCompany', 'isPartner', 'isPRManager'])
  }
})

@Component({
  components: { Subjects },
  mixins: [ResolveCompanyId]
})
export default class PartnerEducationProgramsRoute extends Mappers {
  getCompanyId!: Function

  public companyId!: number

  public created() {
    this.companyId = this.getCompanyId(true)

    if (
      this.isPartner &&
      !this.isPRManager &&
      this.userCompany?.company?.id !== this.companyId
    ) {
      this.$router.push('/error')
    }
  }
}
