




import { Component, Vue } from 'vue-property-decorator'
import PartnerEducationSubjects from '@/components/routes/partner/education-subjects/index.vue'

@Component({ components: { PartnerEducationSubjects } })
export default class PartnerEducationSubjectsRoute extends Vue {}
