





































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import AgGridFactory from '@/api/factories/agGridFactory'
import PartnerEducationProgramsAPI from '@/components/routes/partner/education-subjects/helpers/requests'
import { GridOptions } from 'ag-grid-community'
import _ from 'lodash'

@Component({ components: { AgGridVue } })
export default class AddSubjects extends Vue {
  @Prop(Array) readonly data!: any[]

  private rowData: any[] = []
  private selectedSubjects: any[] = []

  public setSelected() {
    this.onDataChanged()
  }

  private nodeToText = (node: any) => (node ? node.text : '')

  @Watch('data')
  private onDataChanged() {
    this.selectedSubjects = this.data

    this.gridOptions?.api?.forEachNode(node => {
      const foundNode = this.data.find(
        s => s.text === this.nodeToText(node.data)
      )
      if (foundNode) {
        node.setSelected(true)
      }
    })
  }

  private columnDefs = [
    {
      headerName: 'Название дисциплины',
      field: 'text'
    }
  ]

  private gridOptions: GridOptions = {
    rowData: [],
    ...AgGridFactory.getDefaultGridOptions(),
    defaultColDef: {
      ...AgGridFactory.getDefaultGridOptions().defaultColDef,
      filterParams: {
        ...AgGridFactory.getDefaultFilterParams().filterParams,
        filterOptions: ['contains']
      },
      autoHeight: true,
      headerClass: 'AgGridHeader-wrap',
      cellStyle: { 'white-space': 'normal', 'line-height': 1.5 }
    },
    pagination: true,
    paginationPageSize: 30,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    enableCellChangeFlash: true,
    onCellClicked: this.onCellClicked
  }

  private onCellClicked() {
    this.selectedSubjects = _.orderBy(
      (this.gridOptions.api?.getSelectedNodes() ?? []).map(s => ({
        text: s.data.text
      })),
      'cipher'
    )
  }

  private onSave() {
    const postData = _.cloneDeep(this.selectedSubjects)
    this.$emit('save', postData)
    this.selectedSubjects = []
  }

  private async created() {
    const response = await PartnerEducationProgramsAPI.getUniqueSubjects()
    this.rowData = response.data.map((s: any) => ({ text: s.subject }))
  }
}
